import React, { CSSProperties } from 'react';
import { ImageProps } from 'next/image';
import clsx from 'clsx';
import Image from 'next/image';

import { SectionHeader } from '../Section';
import { SectionMain } from '../SectionMain';
import { H1, H1Hero, H2 } from '../Typography';
import { BreakpointName } from '../../../styles/constants/breakpoints/types';
import { gapInPx, GapMultiplier } from '../../../styles/theme';

import classes from './index.module.scss';

import { HtmlRel } from '@/types/HtmlRel';

type ImagePosition = 'left' | 'right';
type ContentAlignX = 'left' | 'center' | 'right';
type ContentAlignY = 'top' | 'center' | 'bottom';

interface ContentAlign {
  x: ContentAlignX;
  y: ContentAlignY;
}

type ImageRwdAction = 'hide' | 'wrap';

interface ImageRwd {
  do: ImageRwdAction;
  breakPointMaxWidth: Exclude<BreakpointName, BreakpointName.BigDesktop>;
  maxWidth?: string;
  gap?: GapMultiplier;
}

interface LayoutSettings {
  headerAlign: 'left' | 'center' | 'right';
  imagePosition: ImagePosition;
  imageAlign: ContentAlign;
  imageRwd: ImageRwd;
  imageGap: GapMultiplier;
  imageGridTemplateColumn: string;
  textGridTemplateColumn: string;
  textAlign: ContentAlign;
  headerGap: GapMultiplier;
}

interface Props {
  columns?: number;
  header?: string;
  headerAnchor?: {
    url: string;
    target?: '_blank';
    rel?: HtmlRel;
  };
  subHeader?: string;
  image?: { containerClassName?: string } & ImageProps;
  isHeroHeader?: boolean;
  layoutSettings?: Partial<LayoutSettings>;
  main?: React.ReactNode;
}

const DEFAULT_LAYOUT_SETTINGS: LayoutSettings = {
  headerGap: 3,
  headerAlign: 'left',
  imageAlign: {
    x: 'center',
    y: 'center',
  },
  imagePosition: 'right',
  imageGap: 6,
  imageGridTemplateColumn: 'auto',
  imageRwd: {
    do: 'wrap',
    breakPointMaxWidth: BreakpointName.Lg,
    maxWidth: '1fr',
  },
  textGridTemplateColumn: 'auto',
  textAlign: {
    y: 'center',
    x: 'center',
  },
};

const Y_TO_CSS_MAP: Record<ContentAlignY, string> = {
  top: 'start',
  center: 'center',
  bottom: 'end',
};

const SectionBody: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  columns = 1,
  header,
  headerAnchor,
  subHeader,
  image = undefined,
  isHeroHeader = false,
  layoutSettings,
  main,
}) => {
  const _layoutSettings: LayoutSettings = Object.assign(
    {},
    DEFAULT_LAYOUT_SETTINGS,
    layoutSettings,
  );

  return (
    <div
      className={clsx(
        classes.layout,
        !!image && [
          classes.layout_withImage,
          classes[
            `layout_withImage_${_layoutSettings.imageRwd.do}_${_layoutSettings.imageRwd.breakPointMaxWidth}`
          ],
          classes[`layout_withImage_${_layoutSettings.imagePosition}`],
          _layoutSettings.imageRwd.gap &&
            classes[`image_gap_${_layoutSettings.imageRwd.breakPointMaxWidth}`],
        ],
      )}
      style={
        image &&
        ({
          columnGap: gapInPx(_layoutSettings.imageGap),
          gridTemplateColumns:
            _layoutSettings.imagePosition === 'right'
              ? `${_layoutSettings.textGridTemplateColumn} ${_layoutSettings.imageGridTemplateColumn}`
              : `${_layoutSettings.imageGridTemplateColumn} ${_layoutSettings.textGridTemplateColumn}`,
          '--section-image-rwd-gap': _layoutSettings.imageRwd.gap
            ? gapInPx(_layoutSettings.imageRwd.gap)
            : '0px',
        } as CSSProperties)
      }
    >
      <div
        className={clsx(classes.regularStuff)}
        style={{
          gap: gapInPx(_layoutSettings.headerGap),
          alignItems: Y_TO_CSS_MAP[_layoutSettings.textAlign.y],
        }}
      >
        {header && (
          <SectionHeader noMargin textAlign={_layoutSettings.headerAlign}>
            {isHeroHeader ? (
              <H1Hero>{header}</H1Hero>
            ) : (
              <H1 disableMargin>
                {headerAnchor ? (
                  <a
                    href={headerAnchor.url}
                    target={headerAnchor.target}
                    rel={headerAnchor.rel}
                  >
                    {header}
                  </a>
                ) : (
                  header
                )}
              </H1>
            )}
            {subHeader && <H2>{subHeader}</H2>}
          </SectionHeader>
        )}
        {main && <SectionMain columns={columns}>{main}</SectionMain>}
      </div>
      {!!image && (
        <div
          className={clsx(
            classes.imageContainer,
            _layoutSettings.imageRwd.do === 'hide' &&
              classes[
                `imageContainer_hide_${_layoutSettings.imageRwd.breakPointMaxWidth}`
              ],
            classes[
              `imageContainer_maxWidth_${_layoutSettings.imageRwd.breakPointMaxWidth}`
            ],
            image.containerClassName,
          )}
          style={
            {
              alignItems: Y_TO_CSS_MAP[_layoutSettings.imageAlign.y],
              '--imageContainerMaxWidth': layoutSettings?.imageRwd?.maxWidth,
            } as CSSProperties
          }
        >
          <Image
            src={image.src}
            alt={image.alt}
            fill={typeof image.src === 'string'}
            sizes={image.sizes}
            style={image.style}
            quality={image.quality}
          />
        </div>
      )}
    </div>
  );
};

export default SectionBody;


    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import Stats from '../Stats';
import useStats from '../Stats/hooks/useStats';
import classes from './index.module.scss';
import SmsapiInNumbersImage from '@/images/home/smsapiInNumbersImage.png';
import LayoutWithLabel from '@/components/common/LayoutWithLabel';
import Button from '@/components/common/Button';
import { Section } from '@/components/common/Section';
import SectionBody from '@/components/common/SectionBody';
import ConfigsContext from '@/contexts/configs';
import { pushToDataLayer } from '@/helpers/googleTagManager';
import { P } from '@/components/common/Typography';
const SmsapiInNumbersSection: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { features } = useContext(ConfigsContext);
    const statistics = useStats();
    const { t } = useTranslation('home');
    const { asPath } = useRouter();
    return (<Section backgroundColor={'white'} disableBorderBottom disableContentPaddingHorizontal>
      <SectionBody main={<LayoutWithLabel layoutSettings={{
                withBackgroundRectangles: true,
            }} label={t('SMSAPI in numbers')} image={{
                src: SmsapiInNumbersImage.src,
                alt: '',
                style: {
                    objectFit: 'cover',
                    objectPosition: '45% 50%',
                    opacity: 0.6,
                },
            }} heading={t('Why SMSAPI?')} main={<div className={classes.layout}>
                <Stats statistics={statistics}/>
                <P className={classes.paragraph} dangerouslySetInnerHTML={{
                    __html: t('Benefit from the potential of SMS communication'),
                }}/>
                <div className={classes.buttons}>
                  {features.isRegistrationEnabled && (<Button className={classes.button} href="/registration" label={t('common::Try for free')} buttonType="callToAction" onClick={() => {
                        pushToDataLayer('btn_click', {
                            pagename: asPath,
                            button: 'registration',
                            component: 'SMSAPI in numbers',
                        });
                    }}/>)}
                  {features.isIndividualOfferButtonVisible && (<Button className={classes.button} href="/individual-offer" label={t('common::Request an offer')} buttonType="primary" icon={{ iconName: 'arrow', placeAfterLabel: true }} onClick={() => {
                        pushToDataLayer('btn_click', {
                            pagename: asPath,
                            button: 'individual offer',
                            component: 'SMSAPI in numbers',
                        });
                    }}/>)}
                </div>
              </div>}/>}/>
    </Section>);
};
export default SmsapiInNumbersSection;

    async function __Next_Translate__getStaticProps__1910ce411ee__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/SmsapiInNumbers/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce411ee__ as getStaticProps }
  
import React, { CSSProperties, FC, ReactNode } from 'react';
import clsx from 'clsx';

import classes from './index.module.scss';

interface Props {
  children: ReactNode;
  columns?: number;
  className?: string;
}

export const SectionMain: FC<Props> = ({ children, columns, className }) => {
  return (
    <main
      style={{ '--columnCountSectionMain': columns } as CSSProperties}
      className={clsx(
        classes.sectionMain,
        columns && classes.sectionMain_columns,
        className,
      )}
    >
      {children}
    </main>
  );
};
